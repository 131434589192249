import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import NotFound from "../views/NotFound.vue";
import LoginView from "../views/LoginView.vue";
import DashUser from "../components/DashUser.vue";
import DashAdmin from "../components/DashAdmin.vue";
import RegisterView from "../views/RegisterView.vue";
import ProfileView from "../components/ProfileView.vue";
import SuccessPayment from "@/components/SuccessPayment.vue";
import DeclinedPayment from "@/components/DeclinedPayment.vue";
import TransactionView from "@/views/TransactionView.vue";
import SuccessFlowVue from "@/components/SuccessFlow.vue";

import store from "../../store";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "UFRO Pagos",
    },
  },
  {
    path: "/flow/return",
    name: "flow-return",
    component: SuccessFlowVue,
    meta: {
      title: "UFRO Pagos",
    },
  },
  {
    path: "/cuenta",
    name: "cuenta",
    component: DashUser,
    meta: {
      title: "Mi Cuenta",
      requireAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      title: "Perfil",
      requireAuth: true,
    },
  },
  {
    path: "/transferencias",
    name: "transferencias",
    component: TransactionView,
    meta: {
      title: "Transferencias",
      requireAuth: true,
    },
  },
  {
    path: "/transferencias/create",
    name: "transaction-create",
    component: TransactionView,
    meta: {
      title: "Transferencias",
      requireAuth: true,
    },
  },
  {
    path: "/transferencias/success",
    name: "create-transferencias-success",
    component: SuccessPayment,
    meta: {
      title: "Transferencias",
      requireAuth: true,
    },
  },
  {
    path: "/transferencias/failed",
    name: "create-transferencias-failed",
    component: DeclinedPayment,
    meta: {
      title: "Transferencias",
      requireAuth: true,
    },
  },
  {
    path: "/admin/dashboard",
    name: "dashboard-admin",
    component: DashAdmin,
    meta: {
      title: "Dashboard",
      requireAuth: true,
      requireAdmin: true,
    },
  },
  {
    path: "/auth/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "Iniciar Sesión",
    },
  },
  {
    path: "/auth/register",
    name: "register",
    component: RegisterView,
    meta: {
      title: "Registrar",
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 | Not Found",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.API_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.name === "home") {
  //   next();
  // }

  const rutaProtegida = to.matched.some((record) => record.meta.requireAuth);
  const rutaAdmin = to.matched.some((record) => record.meta.requireAdmin);
  const haveToken = store.state.auth.user?.token;
  const isAdmin = store.state.auth.roles?.includes("ROLE_ADMIN");

  if (rutaProtegida && !haveToken) {
    next({ name: "login" });
  }
  if (rutaAdmin && !isAdmin) {
    next({ name: "home" });
  }
  if (haveToken && (to.name === "login" || to.name === "register")) {
    next({ name: "home" });
  }
  next();
  // const user = store.state.auth.user;
  // if (user && (to.name === "login" || to.name === "register")) {
  //   next({ name: "home" }); // Redirect to the home page if the user is already logged in
  // } else if (!user && to.name !== "login" && to.name !== "register") {
  //   next({ name: "login" }); // Redirect to the login page if the user is not logged in
  // } else {
  //   document.title = (to.meta.title as string) || "Ufro Pagos";
  //   next();
  // }
});

export default router;
