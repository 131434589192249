<template>
  <PayService :dismissModal="dismissModal" :service="service" v-if="service" />
  <section class="bg-gray-50 dark:bg-gray-900">
    <div class="mx-auto max-w-screen-2xl gap-5 flex flex-col">
      <div v-for="service in services" :key="service._id">
        <div class="flex flex-row justify-between">
          <div class="flex flex-col justify-between">
            <span class="text-xl font-medium text-gray-700 dark:text-white">
              {{ service.service.name }}
            </span>
            <span
              class="text-xl font-medium text-gray-700 dark:text-white opacity-60"
            >
              {{ service.service.desc }}
            </span>
          </div>
          <div class="flex flex-col justify-between">
            <span
              class="text-xl font-medium text-gray-700 dark:text-white opacity"
            >
              Monto
            </span>
            <span
              class="text-xl font-medium text-gray-700 dark:text-white opacity-60"
            >
              {{ formarMoney(service.service.amount) }}
            </span>
          </div>
        </div>
        <button
          class="mt-2 px-6 text-sm text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-lg py-2"
          @click="payService(service)"
        >
          Pagar
        </button>
      </div>

      <span
        class="text-l font-medium text-gray-700 dark:text-white opacity-80"
        v-if="services.length === 0"
      >
        No posees servicios por pagar.
      </span>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getMyServices } from "@/components/apirequests";
import { Service } from "@/interfaces";
import store from "../../../store";
import PayService from "../modals/PayService.vue";

export default defineComponent({
  name: "MyServices",
  data() {
    return {
      services: [] as Service[],
      service: null as Service | null,
    };
  },
  mounted() {
    this.getAllTransactions();
  },
  components: {
    PayService,
  },
  methods: {
    async getAllTransactions() {
      try {
        const response = await getMyServices(store.state.auth.user.token);
        this.services = response;
      } catch (error) {}
    },
    formarMoney(value: number) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
    async payService(service: Service) {
      this.service = service;
    },
    dismissModal() {
      this.service = null;
    },
  },
});
</script>
