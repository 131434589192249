<template>
  <!-- Main modal -->
  <div
    id="updateProductModal"
    tabindex="-1"
    class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50"
  >
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
      <!-- Modal content -->
      <div
        class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5"
      >
        <!-- Modal header -->
        <div
          class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Pago de servicio
          </h3>
          <button
            type="button"
            @click="
              dismissModal();
              cupon = '';
            "
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="updateProductModal"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <ErrorDisplay :loginError="error" />
        <form @submit.prevent="payService()">
          <div class="grid gap-4 mb-4 sm:grid-cols-1">
            <div class="col-span-2">
              <label
                for="cupon"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Cupon (opcional)</label
              >
              <input
                type="text"
                name="cupon"
                id="cupon"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Cupon de descuento"
                v-model="cupon"
              />
            </div>
          </div>
          <div class="flex items-center space-x-4">
            <button
              type="submit"
              class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Pagar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Loading v-if="loading" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Service } from "@/interfaces";
import { payService } from "@/components/apirequests";
import store from "../../../store";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import Loading from "../Loading.vue";

export default defineComponent({
  name: "AddFondos",
  props: {
    dismissModal: {
      type: Function,
      required: true,
    },
    service: {
      type: Object as () => Service | null,
      required: true,
    },
  },
  data() {
    return {
      cupon: null as string | null,
      error: "",
      loading: false,
    };
  },
  components: {
    ErrorDisplay,
    Loading,
  },
  methods: {
    async payService() {
      this.loading = true;
      this.error = "";
      try {
        const data: any = {
          idSertUser: this.service?._id,
        };
        if (this.cupon) {
          data["cupon"] = this.cupon;
        }
        const response = await payService({
          token: store.state.auth.user.token,
          data,
        });
        console.log(response);

        if (response) {
          this.$router.push({ name: "create-transferencias-success" });
        }
      } catch (error: any) {
        this.error = error ?? "Algo salió mal";
        console.log(error);
      }
      this.loading = false;
    },
  },
});
</script>
