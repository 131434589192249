<template>
  <Loading v-if="loading" />
  <div class="antialiased bg-gray-50 dark:bg-gray-900 min-h-screen" v-else>
    <AddFondos
      v-if="showModalFondos"
      :dismissModal="dismissModal"
      :createTransaction="createTransaction"
    />
    <main class="p-4 h-auto pt-10 max-w-7xl m-auto">
      <div class="grid grid-cols-2 gap-4 mb-12">
        <div
          class="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72 justify-between flex flex-col p-4"
        >
          <div>
            <div class="flex flex-col mb-5">
              <span
                class="text-xl font-medium text-gray-700 dark:text-white opacity-80"
              >
                Cuenta UFRO
              </span>
              <span
                class="text-2xl font-semibold text-gray-700 dark:text-white"
              >
                {{ account.number ?? "" }}
              </span>
            </div>

            <div class="flex flex-row items-center gap-3">
              <span
                class="text-2xl font-medium text-gray-700 dark:text-white opacity-80"
              >
                Saldo
              </span>
              <span
                class="text-3xl font-semibold text-gray-700 dark:text-white"
              >
                {{ formarMoney(account.balance) ?? formarMoney(0) }}
              </span>
            </div>
          </div>

          <div class="flex justify-between">
            <span class="text-sm text-gray-700 dark:text-white opacity-80">
              Fecha activación:
              {{ formDate(account.created_at ?? account.createdAt) ?? "" }}
            </span>
            <button
              @click="showModalFondos = true"
              class="text-sm text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-lg px-4 py-2"
            >
              Agregar Fondos
            </button>
          </div>
        </div>
        <div class="p-2">
          <h2 class="text-2xl font-semibold text-gray-700 dark:text-white mb-2">
            Servicios
          </h2>
          <MyServices />
        </div>
      </div>

      <div
        class="border-dashed rounded-lg border-gray-300 dark:border-gray-600 mb-4"
      >
        <h2 class="text-2xl font-semibold text-gray-700 dark:text-white">
          Últimos movimientos
        </h2>
        <MovementsTable />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MovementsTable from "@/components/user/MovementsTable.vue";
import MyServices from "./user/MyServices.vue";
import store from "../../store";
import Loading from "./Loading.vue";
import AddFondos from "./modals/AddFondos.vue";
import { makeTansactionFlow, getAccount } from "@/components/apirequests";

export default defineComponent({
  name: "DashUser",
  components: {
    MovementsTable,
    MyServices,
    Loading,
    AddFondos,
  },
  data() {
    return {
      account: store.state.auth.user.user.account,
      loading: true,
      showModalFondos: false,
    };
  },
  mounted() {
    this.getAcc();
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    formarMoney(value: number) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
    formDate(date: string) {
      return new Date(date).toLocaleDateString("es-CL");
    },
    async createTransaction(amount: number) {
      this.loading = true;

      const data = {
        amount,
      };

      const response = await makeTansactionFlow({
        token: store.state.auth.user.token,
        data,
      });

      console.log(response);

      window.location.href = response;

      this.dismissModal();
    },
    async getAcc() {
      try {
        const response = await getAccount(store.state.auth.user.token);
        this.account = response;
      } catch (error: any) {
        console.log(error);
      }
    },
    dismissModal() {
      this.showModalFondos = false;
    },
  },
});
</script>
