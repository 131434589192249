// store.ts
import { createDirectStore } from "direct-vuex";
import auth from "./auth";

const { store, rootActionContext, moduleActionContext } = createDirectStore({
  modules: {
    auth,
  },
});
// Export the direct-store instead of the classic Vuex store.
export default store;
// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext };
// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}
