// module1.ts

import {
  postLogin,
  postRegister,
  getAllServices,
} from "./src/components/apirequests";

export default {
  namespaced: true as const,
  state: {
    user: null,
    roles: [],
    dark: true,
    servicios: [],
  } as any,
  getters: {
    dark: (state: { dark: any }) => state.dark,
  },
  mutations: {
    SET_USER(state: { user: any; roles: any }, user: any) {
      state.user = user;
      state.roles = parseJwt(user) ?? [];
    },
    GET_USER(state: { user: any }) {
      return state.user;
    },
    GET_ROLES(state: { user: any }) {
      return state.user.roles;
    },
    GET_SERVICIOS(state: { servicios: any }) {
      return state.servicios;
    },
    SET_DARK: (state: { dark: any }, bool: boolean) => {
      state.dark = bool;
    },
    SET_SERVICIOS: (state: { servicios: any }, servicios: any) => {
      state.servicios = servicios;
    },
  },
  actions: {
    async loginUser(
      { commit }: any,
      payload: { email: string; password: string }
    ) {
      try {
        const response = await postLogin(payload.email, payload.password);
        commit("SET_USER", response);
        localStorage.setItem("user", JSON.stringify(response));
        return response;
      } catch (error: any) {
        throw new Error(error.response.data?.error ?? "Algo salió mal");
      }
    },
    async registerUser(
      { commit }: any,
      payload: { email: string; password: string; rut: string; name: string }
    ) {
      try {
        const response = await postRegister(
          payload.email,
          payload.password,
          payload.rut,
          payload.name
        );
        commit("SET_USER", response);
        localStorage.setItem("user", JSON.stringify(response));
      } catch (error: any) {
        throw new Error("Algo salió mal");
      }
    },
    async getServices({ commit }: any, token: string) {
      try {
        const response = await getAllServices(token);
        console.log(response);
        commit("SET_SERVICIOS", response);
        localStorage.setItem("servicios", JSON.stringify(response));
        return response;
      } catch (error: any) {
        throw new Error("Algo salió mal");
      }
    },

    async logoutUser({ commit }: any) {
      try {
        localStorage.removeItem("user");
        commit("SET_USER", null);
      } catch (error) {
        console.error("Error while logging out:", error);
      }
    },
  },
};

export function parseJwt(user: object) {
  const _user: any = user;
  let Buffer = require("buffer/").Buffer;
  if (!_user?.token) {
    return [];
  }

  const content = Buffer.from(_user.token.split(".")[1], "base64").toString();

  const _data = JSON.parse(content);
  return _data.roles;
}
