<template>
  <section class="bg-white dark:bg-gray-900 h-screen">
    <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
      <h2
        class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"
      >
        Perfil
      </h2>
      <p
        class="mb-8 lg:mb-16 font-light text-gray-500 dark:text-gray-400 sm:text-xl"
      >
        Configura y actualiza tu perfil de usuario.
      </p>
      <form action="#" class="space-y-8">
        <div class="grid gap-4 mb-4 sm:grid-cols-2">
          <div>
            <label
              for="name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Nombres</label
            >
            <input
              type="text"
              name="name"
              id="name"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Nombre"
              v-model="user.user.name"
            />
          </div>
          <div>
            <label
              for="lastname"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Apellidos</label
            >
            <input
              type="text"
              name="lastname"
              id="lastname"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Apellido"
              v-model="user.user.lastname"
            />
          </div>
        </div>
        <div class="grid gap-4 mb-4 sm:grid-cols-2">
          <div>
            <label
              for="name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Rut</label
            >
            <input
              type="text"
              name="rut"
              id="rut"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Nombre"
              v-model="user.user.rut"
              disabled
            />
          </div>
          <div>
            <label
              for="cellphone"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >N. Telefono</label
            >
            <input
              type="text"
              name="cellphone"
              id="cellphone"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Celular"
              v-model="user.user.cellphone"
            />
          </div>
        </div>
        <div>
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Email</label
          >
          <input
            type="email"
            id="email"
            class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
            placeholder="name@ufromail.cl"
            v-model="user.user.email"
            disabled
          />
        </div>

        <button
          type="submit"
          class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        >
          Actualizar
        </button>
      </form>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import store from "../../store";
export default defineComponent({
  name: "ProfileView",
  data() {
    return {
      user: store.state.auth.user,
    };
  },
});
</script>
