<template>
  <tr
    class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
  >
    <th
      scope="row"
      class="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ user.name }}
    </th>
    <td class="px-4 py-2">
      {{ user.rut }}
    </td>
    <td class="px-4 py-2">
      {{ user?.email }}
    </td>
    <td
      class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ convertDate(user.created_at ?? user.createdAt) }}
    </td>

    <td class="px-4 py-2">
      <span
        v-if="user?.status == 'blocked'"
        class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-red-600 dark:text-primary-900"
        >Bloqueado</span
      >
      <span
        v-if="user?.status == 'inactive'"
        class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-yellow-400 dark:text-primary-950"
        >Inactivo</span
      >
      <span
        v-if="user?.status == 'active'"
        class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-green-700 dark:text-primary-100"
        >Activo</span
      >
    </td>
  </tr>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "UserRow",
  props: {
    user: {
      type: Object as () => any,
      required: true,
    },
  },
  methods: {
    convertDate(date: Date) {
      return new Date(date).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    openExternalURLInNewWindow(url: string) {
      window.open(url, "_blank");
    },
  },
});
</script>
