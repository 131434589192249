import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700" }
const _hoisted_2 = { class: "px-4 py-2" }
const _hoisted_3 = { class: "px-4 py-2" }
const _hoisted_4 = { class: "px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white" }
const _hoisted_5 = {
  scope: "row",
  class: "flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
}
const _hoisted_6 = { class: "px-4 py-2" }
const _hoisted_7 = {
  key: 0,
  class: "bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-red-600 dark:text-primary-100"
}
const _hoisted_8 = {
  key: 1,
  class: "bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-green-700 dark:text-primary-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.cupon.code), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.cupon.description), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.formarMoney(_ctx.cupon.amount)), 1),
    _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.formDate(_ctx.cupon.created_at ?? _ctx.cupon.createdAt)), 1),
    _createElementVNode("td", _hoisted_6, [
      (!_ctx.cupon.isEnable)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Desactivado"))
        : _createCommentVNode("", true),
      (_ctx.cupon.isEnable)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Activo"))
        : _createCommentVNode("", true)
    ])
  ]))
}