<template>
  <div
    id="app"
    :class="isDark ? 'dark' : 'light'"
    class="bg-white dark:bg-black min-h-full"
  >
    <Header v-if="!isAuthScreen" />
    <router-view />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import store from "../store";
import Header from "./components/Header.vue";

export default defineComponent({
  computed: {
    currentUser() {
      return store.state.auth.user;
    },
    isDark() {
      return store.state.auth.dark;
    },
    isAuthScreen() {
      return (
        this.$route.path.includes("/auth") ||
        this.$route.path.includes("/flow") ||
        this.$route.path.includes("/success") ||
        this.$route.path.includes("/failed")
      );
    },
  },
  components: {
    Header,
  },
});
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
