<template>
  <Loading v-if="loading" />
  <section class="bg-white dark:bg-gray-900 h-full">
    <div
      class="py-8 lg:py-16 px-4 mx-auto max-w-screen-xl sm:grid-cols-7 grid grid-flow-row gap-8"
    >
      <div class="col-span-4 flex-auto">
        <h2
          class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"
        >
          Realizar Transaccion
        </h2>
        <p
          class="mb-8 lg:mb-16 font-light text-gray-500 dark:text-gray-400 sm:text-xl"
        >
          Realiza una transaccion a un usuario.
        </p>
        <ErrorDisplay :loginError="loginError" />
        <form @submit.prevent="makeTransaction" class="space-y-8">
          <div class="grid gap-4 mb-4">
            <div>
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Cuenta de Origen</label
              >
              <select
                name="account"
                id="account"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                v-model="accountorigin"
              >
                <option value="Selecciona una cuenta" disabled selected>
                  Selecciona una cuenta
                </option>
                <option
                  v-for="account in accounts"
                  :key="account.number"
                  :value="account.number"
                >
                  {{ account.number }} - ${{ account.balance }}
                </option>
              </select>
            </div>
            <div>
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Nombres</label
              >
              <input
                type="text"
                name="name"
                id="name"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Nombre"
                v-model="name"
              />
            </div>
            <div>
              <label
                for="rut"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >RUT</label
              >
              <input
                type="text"
                name="rut"
                id="rut"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="RUT"
                v-model="rut"
              />
            </div>

            <div>
              <label
                for="acc"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Numero de Cuenta</label
              >
              <input
                type="text"
                name="acc"
                id="acc"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Numero de Cuenta"
                v-model="acc"
              />
            </div>
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Correo</label
              >
              <input
                type="email"
                name="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Correo"
                v-model="email"
              />
            </div>
          </div>
          <div>
            <label
              for="amount"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >Monto</label
            >
            <input
              type="number"
              id="amount"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Monto"
              v-model="amount"
            />
          </div>
          <div class="sm:col-span-2">
            <label
              for="message"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Mensaje</label
            >
            <textarea
              id="message"
              rows="5"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Deja un mensaje..."
              v-model="message"
            ></textarea>
          </div>

          <button
            type="submit"
            class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            Hacer Transaccion
          </button>
        </form>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import Loading from "@/components/Loading.vue";
import store from "../../store";
import { makeTransactionMethod, getAccount } from "@/components/apirequests";

export default defineComponent({
  name: "TransactionView",
  metaInfo: {
    title: "Login",
  },
  components: {
    ErrorDisplay,
    Loading,
  },
  data() {
    return {
      email: "",
      name: "",
      rut: "",
      acc: "",
      amount: 0,
      loginError: "",
      loading: false,
      message: "",
      accounts: [] as any,
      accountorigin: null,
    };
  },
  mounted() {
    this.getAcc();
  },
  methods: {
    async makeTransaction() {
      this.loginError = "";
      this.loading = true;
      try {
        const data = {
          to: this.acc,
          amount: this.amount,
          comment: this.message,
          from: this.accountorigin,
        };
        const response = await makeTransactionMethod({
          token: store.state.auth.user.token,
          data,
        });

        console.log(response);
        this.loading = false;

        this.$router.push({ name: "create-transferencias-success" });
      } catch (error: any) {
        console.log(error);
        this.loginError = error ?? "Algo salio mal";
        this.loading = false;
      }
    },
    async getAcc() {
      try {
        const response = await getAccount(store.state.auth.user.token);

        this.accounts = [response];
      } catch (error: any) {
        console.log(error);
      }
    },
  },
});
</script>
