<template>
  <section class="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5">
    <div class="mx-auto max-w-screen-2xl">
      <div
        class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-md"
      >
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <caption>
            <!-- Tabla de movimientos de la cuenta -->
          </caption>
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-4 py-3">Fecha</th>
              <th scope="col" class="px-4 py-3">Desc</th>
              <th scope="col" class="px-4 py-3">Desde</th>
              <th scope="col" class="px-4 py-3">Hacia</th>
              <th scope="col" class="px-4 py-3">Monto</th>
              <th scope="col" class="px-4 py-3">Estado</th>
            </tr>
          </thead>
          <tbody>
            <TransactionRow
              v-for="transaction in transactions"
              :key="transaction._id"
              :transaction="transaction"
            />
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TransactionRow from "@/components/user/TransactionRow.vue";
import { getAllTransactions } from "@/components/apirequests";
import store from "../../../store";
import { Transaction } from "@/interfaces";

export default defineComponent({
  name: "TransactionsAdmin",
  data() {
    return {
      transactions: [] as Transaction[],
    };
  },
  components: {
    TransactionRow,
  },
  mounted() {
    this.getAllTransactions();
  },
  methods: {
    async getAllTransactions() {
      try {
        const response = await getAllTransactions(store.state.auth.user.token);
        console.log(response);
        this.transactions = response;
      } catch (error) {}
    },
  },
});
</script>
