<template>
  <tr
    class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
  >
    <th
      scope="row"
      class="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ formDate(transaction?.created_at ?? transaction.createdAt ?? "-") }}
    </th>
    <td class="px-4 py-2">
      {{ transaction?.comment ?? "" }}
    </td>
    <td class="px-4 py-2">
      {{ getUser(transaction.from) ?? "" }}
    </td>
    <td class="px-4 py-2">
      {{ getUser(transaction.to) ?? "" }}
    </td>
    <td
      class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ formarMoney(transaction?.amount ?? 0) }}
    </td>

    <td class="px-4 py-2">
      <span
        v-if="transaction?.status == 'failed'"
        class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-red-600 dark:text-primary-900"
        >Cancelado</span
      >
      <span
        v-if="transaction?.status == 'pending'"
        class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-yellow-400 dark:text-primary-950"
        >Pendiente</span
      >
      <span
        v-if="transaction?.status == 'completed'"
        class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-green-700 dark:text-primary-100"
        >Completado</span
      >
    </td>
  </tr>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TransactionRow",
  props: {
    transaction: {
      type: Object as () => any,
      required: true,
    },
  },
  methods: {
    convertDate(date: Date) {
      return new Date(date).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    openExternalURLInNewWindow(url: string) {
      window.open(url, "_blank");
    },
    formarMoney(value: number) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
    formDate(date: string) {
      return new Date(date).toLocaleDateString("es-CL");
    },
    getUser(nAccount: any) {
      console.log(nAccount);
      console.log(nAccount?.user);
      return nAccount?.user?.name ?? "Desconocido";
    },
  },
});
</script>
