<template>
  <Loading v-if="loading" />
  <section class="bg-gray-50 dark:bg-gray-900">
    <div
      class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0"
    >
      <a
        href="#"
        class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
      >
        <img class="w-8 h-8 mr-2" src="../assets/icon.svg" alt="logo" />
        UFRO Pagos
      </a>
      <div
        class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700"
      >
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1
            class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
          >
            Crear cuenta
          </h1>

          <ErrorDisplay :loginError="loginError" />
          <form
            class="space-y-4 md:space-y-6"
            @submit.prevent="register"
            autocomplete="off"
          >
            <div>
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Nombre</label
              >
              <input
                autocomplete="off"
                type="text"
                name="name"
                id="name"
                v-model="name"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Juan Pérez"
                required
              />
            </div>
            <div>
              <label
                for="rut"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >RUT</label
              >
              <input
                autocomplete="off"
                type="text"
                name="rut"
                id="rut"
                v-model="rut"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="12.456.879-8"
                required
              />
            </div>
            <div>
              <label
                for="email"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Correo</label
              >
              <input
                type="email"
                name="email"
                id="email"
                v-model="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@ufromail.cl"
                required
                autocomplete="off"
              />
            </div>
            <div>
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Contraseña</label
              >
              <input
                type="password"
                name="password"
                id="password"
                v-model="password"
                placeholder="••••••••"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <button
              type="submit"
              class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Crear cuenta
            </button>
            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              ¿Ya tienes una cuenta?
              <a
                href="/auth/login"
                class="font-medium text-primary-600 hover:underline dark:text-primary-500"
              >
                Inicia sesión
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import Loading from "@/components/Loading.vue";

import store from "../../store";
export default defineComponent({
  name: "RegisterView",
  metaInfo: {
    title: "Register",
  },
  components: {
    ErrorDisplay,
    Loading,
  },
  data() {
    return {
      email: "",
      password: "",
      name: "",
      rut: "",
      loginError: "",
      loading: false,
    };
  },
  created() {},
  methods: {
    async register() {
      this.loginError = "";
      this.loading = true;
      try {
        const email = this.email;
        const password = this.password;
        const name = this.name;
        const rut = this.limpiarString(this.rut);
        await store.dispatch.auth.registerUser({ email, password, name, rut });

        // redirect to '/'
        this.$router.push("/");
        this.loading = false;
      } catch (error: any) {
        this.loginError = "Algo salio mal";
        this.loading = false;
      }
    },
    limpiarString(cadena: string) {
      // Utiliza una expresión regular para eliminar todos los caracteres excepto los números y la letra 'k'
      return cadena.replace(/[^0-9kK]/g, "");
    },
  },
});
</script>
