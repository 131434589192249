<template>
  <footer class="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800">
    <div class="mx-auto max-w-screen-xl text-center">
      <a
        href="#"
        class="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white"
      >
        UFRO Pagos
      </a>
      <p class="my-6 text-gray-500 dark:text-gray-400"></p>

      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400"
        >© 2023 <a href="#" class="hover:underline">UFRO-Pagos™</a></span
      >
    </div>
  </footer>
</template>
