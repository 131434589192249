<template>
  <tr
    class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
  >
    <th
      scope="row"
      class="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ service.name }}
    </th>
    <td class="px-4 py-2">
      {{ service.code }}
    </td>
    <td class="px-4 py-2">
      {{ service?.desc }}
    </td>
    <td
      class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ formarMoney(service.amount) }}
    </td>
    <td
      class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ formarMoney(service.account.balance) }}
    </td>

    <td
      class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      #
    </td>
  </tr>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IService } from "@/interfaces";

export default defineComponent({
  name: "ServiceRow",
  props: {
    service: {
      type: Object as () => IService,
      required: true,
    },
  },
  methods: {
    formarMoney(value: number) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
  },
});
</script>
