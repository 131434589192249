<template>
  <ServiceModal
    v-if="showServiceModal"
    :dismissModal="dismissModal"
    :createService="createService"
    :error="error"
  />

  <div class="flex items-center justify-between">
    <h2 class="text-2xl font-semibold text-gray-700 dark:text-white">
      Servicios
    </h2>
    <button
      type="button"
      @click="showServiceModal = true"
      class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
    >
      Crear Servicio
    </button>
  </div>

  <section class="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5">
    <div class="mx-auto max-w-screen-2xl">
      <div
        class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-md"
      >
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <caption>
            <!-- Tabla de movimientos de la cuenta -->
          </caption>
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-4 py-3">Nombre</th>
              <th scope="col" class="px-4 py-3">Codigo</th>
              <th scope="col" class="px-4 py-3">Descripcion</th>
              <th scope="col" class="px-4 py-3">Monto</th>
              <th scope="col" class="px-4 py-3">Recaudado</th>
              <th scope="col" class="px-4 py-3">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <ServiceRow
              v-for="service in services"
              :key="service._id"
              :service="service"
            />
          </tbody>
        </table>
      </div>
    </div>
  </section>
  <ErrorMessage v-if="error" :dismissModal="dismissModalError" :error="error" />
  <Loading v-if="loading" />
</template>
<script lang="ts">
import { createService } from "@/components/apirequests";
import { defineComponent } from "vue";
import store from "../../../store";
import ServiceRow from "./ServiceRow.vue";
import { IService } from "@/interfaces";
import ServiceModal from "@/components/modals/ServiceModal.vue";
import Loading from "@/components/Loading.vue";
import ErrorMessage from "../modals/ErrorMessage.vue";

export default defineComponent({
  name: "Services",
  data() {
    return {
      services: store.state.auth.services as IService[] | [],
      showServiceModal: false,
      loading: false,
      error: "",
    };
  },
  components: {
    ServiceRow,
    ServiceModal,
    Loading,
    ErrorMessage,
  },
  methods: {
    async getAllServicesApp() {
      const response = await store.dispatch.auth.getServices(
        store.state.auth.user.token
      );
      console.log(response, "response");
      this.services = response;
    },
    dismissModal() {
      this.showServiceModal = false;
    },
    async createService(data: {
      name: string;
      code: string;
      description: string;
      price: number;
    }) {
      this.loading = true;

      try {
        const response = await createService({
          token: store.state.auth.user.token,
          data,
        });

        if (response) {
          this.getAllServicesApp();
          this.dismissModal();
          this.loading = false;
        }
      } catch (error: any) {
        this.error = typeof error === "string" ? error : "Algo salió mal";
        this.loading = false;
      }
    },
    dismissModalError() {
      this.error = "";
    },
  },
  mounted() {
    this.getAllServicesApp();
  },
});
</script>
