<template>
  <tr
    class="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
  >
    <td class="px-4 py-2">
      {{ cupon.code }}
    </td>
    <td class="px-4 py-2">
      {{ cupon.description }}
    </td>
    <td
      class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ formarMoney(cupon.amount) }}
    </td>
    <th
      scope="row"
      class="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
    >
      {{ formDate(cupon.created_at ?? cupon.createdAt) }}
    </th>
    <td class="px-4 py-2">
      <span
        v-if="!cupon.isEnable"
        class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-red-600 dark:text-primary-100"
        >Desactivado</span
      >
      <span
        v-if="cupon.isEnable"
        class="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-green-700 dark:text-primary-100"
        >Activo</span
      >
    </td>
  </tr>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import store from "../../../store";

export default defineComponent({
  name: "CuponRow",
  props: {
    cupon: {
      type: Object as () => any,
      required: true,
    },
  },
  methods: {
    convertDate(date: Date) {
      return new Date(date).toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    openExternalURLInNewWindow(url: string) {
      window.open(url, "_blank");
    },
    formarMoney(value: number) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },
    formDate(date: string) {
      return new Date(date).toLocaleDateString("es-CL");
    },
    getUser(nAccount: any) {
      return store.state.auth.user?.user?.account?.number == nAccount
        ? "Mi cuenta"
        : "Desconocido";
    },
  },
});
</script>
