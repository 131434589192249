<template>
  <AsignarServicio
    v-if="showAsignarServiceModal"
    :dismissModal="dismissModal"
    :asignar="asignarService"
    :error="error"
    :usuarios="users"
  />
  <div class="flex items-center justify-between">
    <h2 class="text-2xl font-semibold text-gray-700 dark:text-white">
      Usuarios
    </h2>
    <button
      type="button"
      @click="showAsignarServiceModal = true"
      class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
    >
      Asignar Servicio
    </button>
  </div>
  <section class="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5">
    <div class="mx-auto max-w-screen-2xl">
      <div
        class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-md"
      >
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <caption>
            <!-- Tabla de movimientos de la cuenta -->
          </caption>
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-4 py-3">Nombre</th>
              <th scope="col" class="px-4 py-3">Rut</th>
              <th scope="col" class="px-4 py-3">Email</th>
              <th scope="col" class="px-4 py-3">Fecha Creacion</th>
              <th scope="col" class="px-4 py-3">Estado</th>
            </tr>
          </thead>
          <tbody>
            <UserRow v-for="user in users" :key="user.rut" :user="user" />
          </tbody>
        </table>
      </div>
    </div>
  </section>
  <Loading v-if="loading" />
  <SuccessModal v-if="succesfull" :dismissModal="dismissModal" />
</template>
<script lang="ts">
import { getAllUsersAdmin, asignarServicio } from "@/components/apirequests";
import { defineComponent } from "vue";
import UserRow from "./UserRow.vue";
import store from "../../../store";
import { UserAsAdmin } from "@/interfaces";
import AsignarServicio from "../modals/AsignarServicio.vue";
import Loading from "../Loading.vue";
import SuccessModal from "../modals/SuccessModal.vue";

export default defineComponent({
  name: "UsersTable",
  data() {
    return {
      users: [] as UserAsAdmin[] | [],
      showAsignarServiceModal: false,
      loading: false,
      succesfull: false,
      error: "",
    };
  },
  components: {
    UserRow,
    AsignarServicio,
    Loading,
    SuccessModal,
  },
  methods: {
    async getAllUsers() {
      const response = await getAllUsersAdmin(store.state.auth.user.token);
      this.users = response;
    },
    dismissModal() {
      this.showAsignarServiceModal = false;
      this.succesfull = false;
    },
    async asignarService(rut: string, service: string) {
      console.log(rut, service, "rut, service");
      this.error = "";
      this.loading = true;

      try {
        if (!rut) {
          throw new Error("Debes seleccionar un usuario");
        }
        if (!service) {
          throw new Error("Debes seleccionar un servicio");
        }

        const response = await asignarServicio(
          store.state.auth.user.token,
          rut,
          service
        );

        if (response) {
          this.succesfull = true;
          this.loading = false;
          this.showAsignarServiceModal = false;

          setTimeout(() => {
            this.succesfull = false;
          }, 3000);
        }
      } catch (error: any) {
        this.error = typeof error === "string" ? error : "Algo salió mal";
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getAllUsers();
  },
});
</script>
