import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "../store";
import "./style.css";
const app = createApp(App);

// Load user data from localStorage
// em este caso, se simula que el usuario ya inicio sesion
// to test login, simulte to null
const user = localStorage.getItem("user");
const servicios = localStorage.getItem("servicios");
if (user) {
  store.commit.auth.SET_USER(JSON.parse(user));
}
if (servicios) {
  store.commit.auth.SET_SERVICIOS(JSON.parse(servicios));
}

app.use(router).use(store.original).mount("#app");
