<template>
  <div class="antialiased bg-gray-50 dark:bg-gray-900 items-center h-screen">
    <div class="flex flex-col items-center justify-center h-full">
      <div class="flex flex-col items-center justify-center">
        <svg
          fill="#ef4444"
          viewBox="0 0 256 256"
          id="Flat"
          xmlns="http://www.w3.org/2000/svg"
          class="w-16 h-16 animate-bounce opacity-75"
        >
          <path
            d="M128,20.00012a108,108,0,1,0,108,108A108.12217,108.12217,0,0,0,128,20.00012Zm0,192a84,84,0,1,1,84-84A84.0953,84.0953,0,0,1,128,212.00012Zm40.48535-107.51465L144.9707,128.00012l23.51465,23.51465a12.0001,12.0001,0,0,1-16.9707,16.9707L128,144.97082l-23.51465,23.51465a12.0001,12.0001,0,0,1-16.9707-16.9707l23.51465-23.51465L87.51465,104.48547a12.0001,12.0001,0,0,1,16.9707-16.9707L128,111.02942l23.51465-23.51465a12.0001,12.0001,0,0,1,16.9707,16.9707Z"
          />
        </svg>

        <h2 class="text-2xl font-semibold text-gray-700 dark:text-white mb-2">
          ¡Transacción rechazada!
        </h2>
        <p class="text-gray-700 dark:text-white text-lg">
          Tu transacción no se ha podido realizar.
        </p>
      </div>
      <div class="flex flex-col items-center justify-center mt-10">
        <router-link
          to="/"
          class="text-sm text-gray-700 dark:text-white bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 rounded-lg px-4 py-2"
        >
          Volver al Inicio
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeclinedPayment",
});
</script>
