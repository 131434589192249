import axios from "axios";
import router from "@/router";

const API_URL = process.env.VUE_APP_API_URL;

export const getAllServices = async (token: string) => {
  try {
    const response = await axios.get(`${API_URL}/v1/admin/services`, {
      headers: { "auth-token": `${token}` },
    });
    return response.data.data.services;
  } catch (error: any) {
    throw error;
  }
};

export const getTransactions = async (token: string) => {
  try {
    const response = await axios.get(`${API_URL}/v1/user/transactions`, {
      headers: { "auth-token": `${token}` },
    });
    return response.data.data.transactions;
  } catch (error: any) {
    throw error;
  }
};

export const postLogin = async (email: string, pass: string) => {
  try {
    const response = await axios.post(`${API_URL}/v1/auth/login`, {
      email: email,
      password: pass,
    });
    if (response.data.data.token) {
      return response.data.data;
    }
  } catch (error: any) {
    throw error;
  }
};

export const postRegister = async (
  email: string,
  pass: string,
  rut: string,
  name: string
) => {
  try {
    const response = await axios.post(`${API_URL}/v1/auth/register`, {
      name: name,
      email: email,
      password: pass,
      rut: rut,
    });
    if (response.data.data.token) {
      return response.data.data;
    }
  } catch (error: any) {
    throw error;
  }
};

export const getAllUsersAdmin = async (token: string) => {
  try {
    const response = await axios.get(`${API_URL}/v1/admin/users`, {
      headers: { "auth-token": `${token}` },
    });
    return response.data.data.accounts;
  } catch (error: any) {
    throw error;
  }
};

// get my assigned services
export const getMyServices = async (token: string) => {
  const response = await axios.get(`${API_URL}/v1/user/assigned-services`, {
    headers: { "auth-token": `${token}` },
  });
  return response.data.data.services;
};

export const getAllTransactions = async (token: string) => {
  try {
    const response = await axios.get(`${API_URL}/v1/admin/transactions`, {
      headers: { "auth-token": `${token}` },
    });
    return response.data.data.transactions;
  } catch (error: any) {
    throw error;
  }
};

export const getAllCupons = async (token: string) => {
  try {
    const response = await axios.get(`${API_URL}/v1/admin/cupons`, {
      headers: { "auth-token": `${token}` },
    });
    return response.data.data.cupons;
  } catch (error: any) {
    throw error;
  }
};

export const makeTransactionMethod = async ({
  token,
  data,
}: {
  token: string;
  data: any;
}) => {
  try {
    const response = await axios.post(
      `${API_URL}/v1/transaction/transfer`,
      data,
      {
        headers: { "auth-token": `${token}` },
      }
    );
    return response.data.data.transaction;
  } catch (error: any) {
    console.log(error.response, "general");
    throw error.response.data.error;
  }
};

export const makeTansactionFlow = async ({
  token,
  data,
}: {
  token: string;
  data: any;
}) => {
  try {
    const response = await axios.post(`${API_URL}/v1/transaction/flow`, data, {
      headers: { "auth-token": `${token}` },
    });
    return response.data.data.url;
  } catch (error: any) {
    console.log(error.response, "general");
    throw error.response.data.error;
  }
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const createService = async ({
  token,
  data,
}: {
  token: string;
  data: any;
}) => {
  try {
    const response = await axios.post(`${API_URL}/v1/admin/services`, data, {
      headers: { "auth-token": `${token}` },
    });
    return response.data.data.service;
  } catch (error: any) {
    console.log(error.response, "general");
    throw error.response.data.error;
  }
};

export const payService = async ({
  token,
  data,
}: {
  token: string;
  data: any;
}) => {
  try {
    const response = await axios.post(
      `${API_URL}/v1/transaction/pay-service`,
      data,
      {
        headers: { "auth-token": `${token}` },
      }
    );
    return response.data.data.transaction;
  } catch (error: any) {
    console.log(error.response, "general");
    throw error.response.data.error;
  }
};

export async function getAccount(token: string) {
  console.log(token);
  try {
    const response = await axios.get(`${API_URL}/v1/user/account`, {
      headers: { "auth-token": `${token}` },
    });
    return response.data.data.account;
  } catch (error: any) {
    throw error;
  }
}

export async function asignarServicio(
  token: string,
  usuario: string,
  servicio: string
) {
  try {
    const response = await axios.post(
      `${API_URL}/v1/admin/users/${usuario}/assigned-services/${servicio}`,
      {},
      {
        headers: { "auth-token": `${token}` },
      }
    );
    return response.data.data.message;
  } catch (error: any) {
    throw error;
  }
}
