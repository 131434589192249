import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700" }
const _hoisted_2 = {
  scope: "row",
  class: "flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
}
const _hoisted_3 = { class: "px-4 py-2" }
const _hoisted_4 = { class: "px-4 py-2" }
const _hoisted_5 = { class: "px-4 py-2" }
const _hoisted_6 = { class: "px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white" }
const _hoisted_7 = { class: "px-4 py-2" }
const _hoisted_8 = {
  key: 0,
  class: "bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-red-600 dark:text-primary-900"
}
const _hoisted_9 = {
  key: 1,
  class: "bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-yellow-400 dark:text-primary-950"
}
const _hoisted_10 = {
  key: 2,
  class: "bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-green-700 dark:text-primary-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.formDate(_ctx.transaction?.created_at ?? _ctx.transaction.createdAt ?? "-")), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.transaction?.comment ?? ""), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.getUser(_ctx.transaction.from) ?? ""), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.getUser(_ctx.transaction.to) ?? ""), 1),
    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.formarMoney(_ctx.transaction?.amount ?? 0)), 1),
    _createElementVNode("td", _hoisted_7, [
      (_ctx.transaction?.status == 'failed')
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Cancelado"))
        : _createCommentVNode("", true),
      (_ctx.transaction?.status == 'pending')
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Pendiente"))
        : _createCommentVNode("", true),
      (_ctx.transaction?.status == 'completed')
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Completado"))
        : _createCommentVNode("", true)
    ])
  ]))
}