<template>
  <Loading v-if="loading" />

  <div
    class="antialiased bg-gray-50 dark:bg-gray-900 items-center min-h-screen"
    v-else
  >
    <main class="p-4 h-auto pt-10 max-w-7xl m-auto">
      <!-- button to display service -->

      <!-- <h2 class="text-2xl font-semibold text-gray-700 dark:text-white">
        Cupones
      </h2>
      <CuponesAdmin /> -->

      <ServicesTable />

      <UsersTable />

      <h2 class="text-2xl font-semibold text-gray-700 dark:text-white">
        Transacciones
      </h2>
      <TransactionsAdmin />
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UsersTable from "./admin/UsersTable.vue";
import TransactionsAdmin from "./admin/TransactionsAdmin.vue";
import CuponesAdmin from "./admin/CuponesAdmin.vue";
import Loading from "./Loading.vue";
import ServicesTable from "./admin/ServicesTable.vue";

import AsignarServicio from "./modals/AsignarServicio.vue";

export default defineComponent({
  name: "DashAdmin",
  props: {},
  components: {
    UsersTable,
    TransactionsAdmin,
    CuponesAdmin,
    Loading,
    ServicesTable,

    AsignarServicio,
  },
  data() {
    return {
      loading: true,

      showAsginarServicioModal: false,
    };
  },
  mounted() {
    // wait 3 seconds to show the page
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  methods: {
    // close modal
    dismissModal() {
      this.showAsginarServicioModal = false;
    },
  },
});
</script>
