<template>
  <div
    id="asignarServicioModal"
    tabindex="-1"
    class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50"
  >
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
      <div
        class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5"
      >
        <div
          class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Asignar Servicio
          </h3>
          <button
            type="button"
            @click="dismissModalAc"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="updateProductModal"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <form @submit.prevent="createServiceAction">
          <label
            for="servicio"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Servicio</label
          >
          <select
            name="servicio"
            id="servicio"
            class="bg-gray-50 my-3 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            v-model="servicio"
          >
            <option value="" disabled selected>Seleccione un servicio</option>
            <option
              v-for="service in services"
              :key="service._id"
              :value="service.code"
            >
              {{ service.code }} - {{ service.name }}
            </option>
          </select>
          <label
            for="usuario"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Usuario</label
          >
          <select
            v-model="rut"
            name="usuario"
            id="usuario"
            class="bg-gray-50 border my-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          >
            <option value="" disabled selected>Seleccione un usuario</option>
            <option
              v-for="usuario in usuarios"
              :key="usuario._id"
              :value="usuario.rut"
            >
              {{ usuario.name }} - {{ usuario.rut }}
            </option>
          </select>
          <div class="flex items-center space-x-4">
            <button
              type="submit"
              class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Asignar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UserAsAdmin } from "@/interfaces";
import store from "../../../store";

export default defineComponent({
  name: "AsignarServicio",
  props: {
    dismissModal: {
      type: Function,
      required: true,
    },
    asignar: {
      type: Function,
      required: true,
    },
    error: {
      type: String,
      required: false,
    },
    usuarios: {
      type: Array as () => UserAsAdmin[],
      required: true,
    },
  },
  data() {
    return {
      services: store.state.auth.servicios,
      rut: null as string | null,
      servicio: null as string | null,
    };
  },
  methods: {
    dismissModalAc() {
      this.dismissModal();
    },
    createServiceAction() {
      console.log(this.rut, this.servicio);
      this.asignar(this.rut, this.servicio);
    },
  },
});
</script>
