<template>
  <section class="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5">
    <div class="mx-auto max-w-screen-2xl">
      <div className="grid grid-cols-1 xl:grid-cols-10 gap-4  mb-3 ">
        <div className="flex flex-col col-span-1 xl:col-span-6 gap-1 ">
          <div
            class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-md"
          >
            <table
              class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            >
              <caption>
                <!-- Tabla de movimientos de la cuenta -->
              </caption>
              <thead
                class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-4 py-3">CODIGO</th>
                  <th scope="col" class="px-4 py-3">DESCRIPCION</th>
                  <th scope="col" class="px-4 py-3">MONTO</th>
                  <th scope="col" class="px-4 py-3">FECHA CREACION</th>
                  <th scope="col" class="px-4 py-3">Estado</th>
                </tr>
              </thead>
              <tbody>
                <CuponRow
                  v-for="cupon in cupones"
                  :key="cupon._id"
                  :cupon="cupon"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CuponRow from "./CuponRow.vue";
import store from "../../../store";
import { getAllCupons } from "@/components/apirequests";
import { Cupon } from "@/interfaces";

export default defineComponent({
  name: "CuponesAdmin",
  data() {
    return {
      cupones: [] as Cupon[],
    };
  },
  components: {
    CuponRow,
  },
  mounted() {
    this.getAllTransactions();
  },
  methods: {
    async getAllTransactions() {
      try {
        const response = await getAllCupons(store.state.auth.user.token);
        this.cupones = response;
      } catch (error) {}
    },
  },
});
</script>
